@import './CSS/NewJob.css';
@import './CSS/RankedVehicle.css';
@import './CSS/SchedulerCalendar.css';
@import './CSS/Jobs.css';

.new-order-btn {
    height: 31px !important;
    border-radius: 0 !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    font-size: 14px;
    padding-top: 3px;
    background-color: white;
    color: #343a40;
    width: 50% !important;
}

#header-toolbar-item .e-jobs-btn {
    height: 100%;
}

.scheduler-date-picker {
    max-width: 140px;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    background-color: transparent !important;
}

.scheduler-date-picker:hover span {
    background-color: #6c757d !important;
}

#header-toolbar {
    box-shadow: unset !important;
}

#header-toolbar-item .e-input-group span {
    border-radius: 0 !important;
}

#header-toolbar-item .e-input-group input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

#header-toolbar-item .e-input-group {
    height: 31px !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid;
    border-right: none;
}

#header-toolbar-item .e-input-group:hover {
    background-color: #6c757d !important;
    border: none !important;
}

#header-toolbar-item .e-input-group:hover input {
    background-color: #6c757d !important;
    color: white !important;
    cursor: default;
}

#header-toolbar-item .e-input-group:hover span {
    background-color: #6c757d !important;
    color: white !important;
}

#header-toolbar-item button {
    border: 1px solid;
}

#header-toolbar-item:hover button {
    border: 1px solid #6c757d !important;
}

.scheduler-date-picker:hover::placeholder {
    color: white !important;
}

.duration-datepicker {
    width: 65% !important;
    display: inline-flex !important;
    vertical-align: middle !important;
}

.duration-datepicker span {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.duration-timepicker {
    display: inline-flex !important;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 34% !important;
    font-size: 14px;
    border-radius: 4px;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border: 1px solid #ced4da;
    height: 94.5%;
    font-weight: 500;
    line-height: 5px;
    cursor: pointer;
}

#assignedVehicWrapper .e-multi-select-wrapper {
    padding: 2.5px 32px 2.5px 0 !important;
}

.vehicle-title {
    position: absolute;
    left: 0;
    bottom: 0;
    margin-left: 16px;
}

.modal-spinner-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.e-listview .e-list-item.e-focused  {
    background-color: rgb(151, 198, 37) !important;
    color: white !important;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
    .vehicle-title {
        top: 8px;
    }
  }
  
  /* Medium devices (landscape tablets 768px and up) */
  @media only screen and (min-width: 768px) {
    .vehicle-title {
        top: unset;
    }
  }
  
  /* Large devices (laptops/desktops 992px and up) */
  @media only screen and (min-width: 992px) {
   
  }
  
  /* Extra Large devices (laptops/desktops 1200px and up) */
  @media only screen and (min-width: 1200px) {

  }