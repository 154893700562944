/* @import '../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css'; */

/* ej2-react-navigations */
/* @import "./Assets/css/sidebar/bootstrap5.css"; */

 /* @import "../node_modules/@syncfusion/ej2-react-lists/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css"; */
/* @import "./Assets/css/sidebar/bootstrap5-dark.css"; */

/* ej2-react-scheduler */
/* @import "../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-react-schedule/styles/bootstrap5.css"; */

/* ej2-react-calendar(datepicker) */
/* @import "../node_modules/@syncfusion/ej2-react-calendars/styles/bootstrap5.css"; */

/* @import '../node_modules/@syncfusion/ej2-react-navigations/styles/bootstrap5.css';
@import "../node_modules/@syncfusion/ej2-notifications/styles/bootstrap5.css"; */

/* Data Grid */
/* @import '../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css';   */
/* @import '../node_modules/@syncfusion/ej2-calendars/styles/bootstrap5.css';  
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/bootstrap5.css"; */

/* ej2-react-popups */
/* @import "../node_modules/@syncfusion/ej2-react-buttons/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-react-popups/styles/bootstrap5.css"; */

/* ej2-layout */
/* @import '../node_modules/@syncfusion/ej2-layouts/styles/material.css'; */

/* Icon */
/* @import "../node_modules/@syncfusion/ej2-icons/styles/bootstrap5.css"; */

@import "./Assets/css/sidebar/sidebar.css";
@import './Container/Scheduler/index.css';
@import './Container/RecurringJobs/index.css';
@import './Container/InternalDispatch/index.css';
@import './Container/Report/VMR/index.css';
@import './Container/Customers/index.css';
@import './Container/Drivers/index.css';
@import './Container/Crp/index.css';

/* ej2-spreadsheet */
/* @import '../node_modules/@syncfusion/ej2-react-spreadsheet/styles/material.css'; */

/* Theme Loader */
.theme-loader {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 11000;
}

.theme-loader.dark-theme {
  background: #27292D;
}

.theme-loader.light-theme {
  background: white;
}

.theme-loader-title {
  font-size: 30px;
  font-weight: bold;
}

.theme-loader-title.dark-theme {
  color: white;
}

.theme-loader-title.light-theme {
  color: #27292D;
}


.disable-ripple {
  box-shadow: unset !important;
  overflow: unset !important;
}

/* modal overlay */
.e-dlg-overlay {
  position: fixed !important;
}

.btn-header {
  background-color: transparent !important;
  border-color: transparent !important;
  color: black !important;
}

.btn-header:focus {
  outline: none;
  border-color: transparent !important;
  outline-offset: unset;
  box-shadow: unset !important;
}

.btn-header:hover {
  background-color: #5c636a !important;
  border-color: transparent !important;
  color: #fff !important;
}

.btn-header.selected {
  background-color: #97c625 !important;
  color: white !important;
  transition: background-color 500ms;
}

/* Salary filter */
.salary-filter-btns {
  position: absolute;
  bottom: 0;
  bottom: 0;
}


html, body {
  overflow: hidden;
  height: 100%;
}

#root, .maincontent, .control-section {
  height: 100%;
}

.maincontent {
  overflow: auto;
  height: 86%;
}

.datepicker-transparent {
  background: transparent !important;
}

.appbar-custom {
  box-shadow: none !important;
  border-bottom: 1px solid #82A331 !important;
}

.right-spacing {
  margin-right: 5px !important;
}

.custom-view-switcher {
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 2%;
  margin-top: -2.2%;
  z-index: 5;
}

.dx-buttongroup-item.dx-button.dx-button-mode-contained:not(.dx-item-selected),
.dx-buttongroup-item.dx-button.dx-button-mode-contained.dx-button-normal {
  background-color: transparent !important;
  border: 1px solid #82A331 !important;
  color: #82A331 !important;
}

.dx-buttongroup-item.dx-button.dx-button-mode-contained.dx-button-normal.dx-item-selected, .dx-buttongroup-item.dx-button.dx-button-mode-contained.dx-button-normal.dx-item-selected .dx-icon {
  background-color: #82A331 !important;
  color: white !important;
}

.dx-button-mode-contained .dx-icon {
  color: #82A331 !important;
}

.dx-scheduler-work-space-month .dx-scheduler-appointment-content {
  background-color: #82A331 !important;
}

.dx-scheduler-appointment .dx-resizable-handle-left {
  background-color: #E1E1E1 !important;
}

.dx-scheduler-appointment-collector.dx-button.dx-button-default {
  background-color: #82A331 !important;
}

.dx-scheduler-appointment-content {
  background-color: #82A331 !important;
}

.dx-scheduler-appointment {
  background-color: #E1E1E1 !important;
}

.dx-scheduler-agenda .dx-scheduler-appointment {
  background-color: #82A331 !important;
}

.dx-scheduler-agenda-appointment-marker {
  background-color: #E1E1E1 !important;
}

.dx-scheduler-agenda-appointment-right-layout {
  color: white !important;
}

.dx-scheduler-header-panel-cell.dx-scheduler-header-panel-current-time-cell {
  color: #82A331;
}

.e-toolbar {
  border: 1 solid #edebe9;
  box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);
  /* background-color: #f3f2f1; */
}

.e-jobs-btn {
  border: 2px solid;
  height: 100%;
  margin-right: 2px;
}

.e-cancelled {
  border-color: #6610f2 !important;
  color: #6610f2 !important;
}

.e-btn.e-cancelled:hover, .e-css.e-btn.e-cancelled:hover {
  background-color: #6610f2 !important;
  color: white !important;
}

.e-btn.e-outline.e-cancelled:focus, .e-css.e-btn.e-outline.e-cancelled:focus {
  box-shadow: 0 0 0 4px rgb(102 16 242 / 50%);
}

.e-badge.e-badge-circle {
  height: 20px !important;
  width: 20px !important;
  font-size: 15px !important;
  color: white !important;
  padding: 3px 3px !important;
}

.e-badge-pending {
  background-color: #ffc107 !important;
  margin-left: 50px;
}

.e-badge-approved {
  margin-left: 60px;
  background-color: #dc3545 !important;
}

.e-badge-inprogress {
  margin-left: 70px;
  background-color: #198754 !important;
}

.e-badge-completed {
  margin-left: 67px;
  background-color: #0b5ed7 !important;
}

.e-badge-cancelled {
  margin-left: 59px;
  background-color: #6610f2 !important;
}

.e-badge-default {
  background-color: #6c757d !important;
}

.e-badge-assigned {
  margin-left: 55px;
}

.e-badge-tosite {
  margin-left: 39px;
}

.e-badge-leftsite {
  margin-left: 48px;
}

.e-badge-yard {
  margin-left: 43px;
}

.e-badge-night {
  margin-left: 65px;
}

.btn-color-green {
  background-color: #97c625;
  border-color: #97c625;
}

.btn-color-green:hover {
  background-color: #76a40a;
  border-color: #76a40a;
}

.btn-color-green-outline .btn-color-green:focus {
  background-color: #76a40a;
  border-color: #76a40a;
}

.btn-color-green-outline {
  border: none !important;
  background-color: transparent;
  color: #414345;
}

.btn-color-green-outline:hover {
  border: none !important;
  background-color: transparent;
  color: #97c625;
}

.btn-color-green-outline:focus {
  color: white;
  background-color: #76a40a;
  border-color: #76a40a;
}

.custom-sizing {
  width: 100%;
  border-bottom: 1px solid #97C625;
  padding-bottom: 5px;
}

.header-btn-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.btn-custom-width {
  width: 15%;
}

/* lds-dual-ring */

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
}
.lds-dual-ring.dark-theme:after {
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
.lds-dual-ring.light-theme:after {
  border: 6px solid #27292D;
  border-color: #27292D transparent #27292D transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.component-loader {
  z-index: 19 !important;
}

.component-spinner {
  margin-left: -150px !important;
  margin-top: -150px !important;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

  .component-spinner {
    margin-left: -51px !important;
    margin-top: -150px !important;
  }

  .btn-custom-width {
    width: 100%;
  }

  .header-btn-container {
    display: block !important;
  }

  #mainTable_dialogEdit_wrapper {
    margin: 10px;
  }

  .salary-filter-btns {
    position: relative;
    display: flex;
    margin-left: 0;
    justify-content: center;
  }
}

/* dialog */
.e-dlg-content {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

#dialog-target .e-dlg-container {
  position: fixed !important;
}

/* combobox css */
.e-dropdownbase .e-list-item.e-item-focus, .e-dropdownbase .e-list-item.e-active, .e-dropdownbase .e-list-item.e-active.e-hover, .e-dropdownbase .e-list-item.e-hover {
  background-color:#97c625;
  color: white;
}

/* Toast icons */
.toast-icons {
  font-size: 16px !important;
}
.toast-icons.e-success:before {
  content: '\e72b'
}
.toast-icons.e-info:before {
  content: '\e800'
}
.toast-icons.e-warning:before {
  content: '\e88b'
}
.toast-icons.e-danger:before {
  content: '\e839'
}

/* Service Maintenance */
#service_maintenance_table_toolbarItems {
  padding: 4px 0 4px 0;
}

/* Driver Order Excel */
#driverOrderExcel, #vehicleServiceExcel .e-sheet-panel {
  height: 500px !important;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .btn-custom-width {
    width: 25%;
  }
}

/* Medium devices (landscape tablets 768px and up) */
@media only screen and (min-width: 768px) {
  .btn-custom-width {
    width: 15%;
  }

  .salary-filter-btns {
    position: relative !important;
    margin-left: 0;
  }
}

/* Large devices (laptops/desktops 992px and up) */
@media only screen and (min-width: 992px) {
 
}

/* Extra Large devices (laptops/desktops 1200px and up) */
@media only screen and (min-width: 1200px) {
  .custom-margin-top {
      margin-top: 22px;
  }
}

/* 404 Page */
.not-found-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F0F0F0;
}

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 20px;
  border-radius: 20px;
  background-color: white;
  width: 25%;
}

.not-found h1 {
  margin: 0 0 20px 0;
  text-align: center;
}

.not-found svg {
  height: 20%;
  width: 20%; 
  color: #FFBF00
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .not-found {
    width: 90%;
    margin: 0 10px;
  }

  .not-found svg {
    height: 28%;
    width: 28%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .not-found {
    width: 55%;
  }

  .not-found svg {
    height: 25%;
    width: 25%; 
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 1366px) {
  .not-found {
    width: 35%;
  }

  .not-found svg {
    height: 20%;
    width: 20%; 
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1920px) {
  .not-found {
    width: 25%;
  }

  .not-found svg {
    height: 20%;
    width: 20%; 
  }
}

/* VMR */
#mainTable_toolbarItems {
  height: 43px !important;
}

#Mark_Selected_Reviewed {
  display: none;
  background-color: transparent;
  border: 1px solid #198754;
  color: #198754;
}

#Mark_Selected_Reviewed:hover {
  background-color: #198754;
}

#Mark_Selected_Reviewed:hover span {
  color: white !important;
}

#Mark_Selected_Reviewed span {
  color: #198754;
}

/* readonly fields */
.e-input-group .e-input[readonly], .e-input-group.e-control-wrapper .e-input[readonly] {
  background: unset !important;
}

/* readonly fields - down arrow on numeric textbox */
.e-input-group .e-input[readonly] ~ span.e-input-group-icon, .e-input-group.e-control-wrapper .e-input[readonly] ~ span.e-input-group-icon {
  background: unset !important;
  color: #6c757d !important;
}