#internal_dispatch_table_gridcontrol_toolbarItems {
    height: 45px !important;
    border: none;
}

#internal_dispatch_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(1),
#internal_dispatch_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(2),
#internal_dispatch_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(3),
#internal_dispatch_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(4),
#internal_dispatch_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(5),
#internal_dispatch_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(6),
#internal_dispatch_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(7),
#internal_dispatch_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(8),
#internal_dispatch_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(9) {
    padding: 0 !important;
}

#internal_dispatch_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(1) button,
#internal_dispatch_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(2) button,
#internal_dispatch_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(3) button,
#internal_dispatch_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(4) button,
#internal_dispatch_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(5) button,
#internal_dispatch_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(6) button,
#internal_dispatch_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(7) button,
#internal_dispatch_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(8) button,
#internal_dispatch_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(9) button {
    height: 100%;
    border-radius: 0;
}

#internal_dispatch_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(2) button span,
#internal_dispatch_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(3) button span,
#internal_dispatch_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(4) button span,
#internal_dispatch_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(5) button span {
    color: black;
}

#internal_dispatch_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(2) button {
    height: 100%;
    border-top-left-radius: 10px !important;
}

#internal_dispatch_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(6) button {
    height: 100%;
    border-top-right-radius: 10px!important;
}

#internal_dispatch_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(7) button {
    height: 100%;
    margin-left: 10px;
    border-top-left-radius: 10px !important;
    border: 1px solid #6c757d;
}

#internal_dispatch_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(8) button {
    height: 100%;
    border-top-right-radius: 10px !important;
    border: 1px solid #6c757d;
}

#internal_dispatch_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-left {
    display: inline-block;
}