#startDatepicker, #endDatepicker {
    background: unset !important;
}

.recurring-checkbox {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.recurring-checkbox li {
    display: inline;
    margin-right: 10px;
}