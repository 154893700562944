#filter-acrdn.e-acrdn-item.e-selected > .e-acrdn-header > .e-toggle-icon,
#filter-acrdn.e-acrdn-item.e-selected > .e-acrdn-header > .e-acrdn-header-content {
    color: black !important; 
}

#filter-acrdn.e-acrdn-item.e-selected > .e-acrdn-header {
    background-color: #e1e1e1 !important;
}

#filter-acrdn.e-acrdn-item > .e-acrdn-panel > .e-acrdn-content {
    padding: 7px 20px;
}

.reportDatepicker {
    background: transparent !important;
}