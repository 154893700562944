.ranked-vehicle-container {
    /* width: 98%; */
    min-height: 422px;
    max-height: 422px;
    overflow-y: auto;
    border: 1px solid #ced4da;
    padding: 8px;
}

.ranked-vehicle-list-title {
    color: #97c625;
    font-weight: bold;
    font-size: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.ranked-vehicle-list-count {
    color: #97c625;
    font-weight: bold;
    font-size: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}