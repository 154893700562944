.e-input-group input.e-input, .e-input-group.e-control-wrapper input.e-input {
    height: 35px;
    align-items: center;
}

.e-btn-height {
    height: 35px;
}

.e-ddl.e-input-group .e-input-group-icon,.e-ddl.e-input-group.e-control-wrapper  {
    background-color: white;
}



