/* #btnPending {
    height: calc(100% - 10px);
    min-height: 40px;
    min-width: 40px;
    padding-top: 8px;
} */

#recurring_table_gridcontrol_toolbarItems {
    height: 45px !important;
    border: none;
}

#recurring_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(1),
#recurring_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(2),
#recurring_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(3),
#recurring_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(4),
#recurring_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(5),
#recurring_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(6),
#recurring_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(7),
#recurring_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(8),
#recurring_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(9) {
    padding: 0 !important;
}

#recurring_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(1) button,
#recurring_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(2) button,
#recurring_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(3) button,
#recurring_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(4) button,
#recurring_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(5) button,
#recurring_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(6) button,
#recurring_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(7) button,
#recurring_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(8) button,
#recurring_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(9) button {
    height: 100%;
    border-radius: 0;
}

#recurring_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(2) button span,
#recurring_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(3) button span,
#recurring_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(4) button span,
#recurring_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(5) button span {
    color: black;
}

#recurring_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(2) button {
    height: 100%;
    border-top-left-radius: 10px !important;
}

#recurring_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(5) button {
    height: 100%;
    border-top-right-radius: 10px!important;
}

#recurring_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(6) button {
    height: 100%;
    margin-left: 10px;
    border-top-left-radius: 10px !important;
    border: 1px solid #6c757d;
}

#recurring_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-item:not(.e-separator):nth-child(7) button {
    height: 100%;
    border-top-right-radius: 10px !important;
    border: 1px solid #6c757d;
}

#recurring_table_gridcontrol_toolbarItems .e-toolbar-items .e-toolbar-left {
    display: inline-block;
}

#btnJobs {
    margin-right: 10px;
}

#btnWhatsApp {
    margin-right: 1px;
}

#btnInternal {
    margin-right: 1px;
}

#btnPending {
    background-color: #ffcb00 !important;
    border: 2px solid #ffcb00 !important;
    color: black !important;
    margin-right: 1px;
}

#btnPending:hover, #btnPending:focus {
    background-color: #fecf15;
    border-color: #ffcb00;
}

#btnCommitted {
    background-color: #eeebac !important;
    border-color: #eeebac !important;
    color: black !important;
    margin-right: 1px;
}

#btnCommitted:hover, #btnCommitted:focus {
    background-color: #e4df82;
    border-color: #eeebac;
}

#btnInProgress {
    background-color: #8fd400 !important;
    border: 2px solid #8fd400 !important;
    color: black !important;
    margin-right: 1px;
}

#btnInProgress:hover, #btnInProgress:focus {
    background-color: #9ae10c;
    border-color: #8fd400;
}

#btnCompleted {
    background-color: #0cbadf !important;
    border: 2px solid #0cbadf !important;
    color: black !important;
    margin-right: 1px;
}

#btnCompleted:hover, #btnCompleted:focus {
    background-color: #19bce0;
    border-color: #0cbadf;
}

#btnCancelled {
    background-color: #ff7900 !important;
    border: 2px solid #ff7900 !important;
    margin-right: 1px;
}

#btnCancelled:hover, #btnCancelled:focus {
    background-color: #eb7b19;
    border-color: #ff7900;
}
/* 
td.e-rowcell .statustxt{
    position: relative;
}

td.e-rowcell .statustxt.e-pendingcolor {
    color: #3c3627;
}

td.e-rowcell .statustxt.e-committedcolor {
    color: #3c3627;
}

td.e-rowcell .statustxt.e-inprogresscolor {
    color: #defff0;
}

td.e-rowcell .statustxt.e-completedcolor {
    color: #effcff;
}

td.e-rowcell .statustxt.e-cancelledcolor {
    color: #f57200;
}

.bootstrap5 .e-checkboxfiltertext #Trusttext {
  margin-left: -4px;
}

.statustemp.e-cancelledcolor {
    background-color: #ffb879;
    width: 50%;
}

.statustemp.e-pendingcolor {
    background-color: #ffcc00;
    width: 50%;
}

.statustemp.e-committedcolor {
    background-color: #eeebac;
    width: 50%;
}

.statustemp.e-inprogresscolor {
    background-color: #45e399;
    width: 50%;
}

.statustemp.e-completedcolor {
    background-color: #0cbadf;
    width: 50%;
}

.statustxt.e-pendingcolor {
    color: #ffcb00;
}

.statustxt.e-committedcolor {
    color: #eeebac;
}

.statustxt.e-inprogresscolor {
    color: #1bc777;
}

.statustxt.e-completedcolor {
    color: #0cbadf;
}

.statustxt.e-cancelledcolor {
    color: #ffb879;
}

.statustemp {
    position: relative;
    height: 30px;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
} */

.bg-pending-color {
    background-color: #ffcb00;
}

.bg-committed-color {
    background-color: #eeebac;
}

.bg-inprogress-color {
    background-color: #8fd400;
}

.bg-completed-color {
    background-color: #0cbadf;
}

.bg-cancelled-color {
    background-color: #ff7900;
}

.template-hover:hover {
    color: #b2d865 !important;
    cursor: pointer;
}