/* #btnPending {
    height: calc(100% - 10px);
    min-height: 40px;
    min-width: 40px;
    padding-top: 8px;
} */

#btnPending {
    background-color: #ffcb00;
    border: 2px solid #ffcb00 !important;
}

#btnPending:hover, #btnPending:focus {
    background-color: #fecf15;
    border-color: #ffcb00;
}

#btnCommitted {
    background-color: #eeebac;
    border-color: #eeebac !important;
}

#btnCommitted:hover, #btnCommitted:focus {
    background-color: #e4df82;
    border-color: #eeebac;
}

#btnInProgress {
    background-color: #8fd400;
    border: 2px solid #8fd400 !important;
}

#btnInProgress:hover, #btnInProgress:focus {
    background-color: #9ae10c;
    border-color: #8fd400;
}

#btnCompleted {
    background-color: #0cbadf;
    border: 2px solid #0cbadf !important;
}

#btnCompleted:hover, #btnCompleted:focus {
    background-color: #19bce0;
    border-color: #0cbadf;
}

#btnCancelled {
    background-color: #ff7900;
    border: 2px solid #ff7900 !important;
}

#btnCancelled:hover, #btnCancelled:focus {
    background-color: #eb7b19;
    border-color: #ff7900;
}
/* 
td.e-rowcell .statustxt{
    position: relative;
}

td.e-rowcell .statustxt.e-pendingcolor {
    color: #3c3627;
}

td.e-rowcell .statustxt.e-committedcolor {
    color: #3c3627;
}

td.e-rowcell .statustxt.e-inprogresscolor {
    color: #defff0;
}

td.e-rowcell .statustxt.e-completedcolor {
    color: #effcff;
}

td.e-rowcell .statustxt.e-cancelledcolor {
    color: #f57200;
}

.bootstrap5 .e-checkboxfiltertext #Trusttext {
  margin-left: -4px;
}

.statustemp.e-cancelledcolor {
    background-color: #ffb879;
    width: 50%;
}

.statustemp.e-pendingcolor {
    background-color: #ffcc00;
    width: 50%;
}

.statustemp.e-committedcolor {
    background-color: #eeebac;
    width: 50%;
}

.statustemp.e-inprogresscolor {
    background-color: #45e399;
    width: 50%;
}

.statustemp.e-completedcolor {
    background-color: #0cbadf;
    width: 50%;
}

.statustxt.e-pendingcolor {
    color: #ffcb00;
}

.statustxt.e-committedcolor {
    color: #eeebac;
}

.statustxt.e-inprogresscolor {
    color: #1bc777;
}

.statustxt.e-completedcolor {
    color: #0cbadf;
}

.statustxt.e-cancelledcolor {
    color: #ffb879;
}

.statustemp {
    position: relative;
    height: 30px;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
} */

.bg-pending-color {
    background-color: #ffcb00;
}

.bg-committed-color {
    background-color: #eeebac;
}

.bg-inprogress-color {
    background-color: #8fd400;
}

.bg-completed-color {
    background-color: #0cbadf;
}

.bg-cancelled-color {
    background-color: #ff7900;
}

.template-hover:hover {
    color: #b2d865 !important;
    cursor: pointer;
}