
/* #customerId .e-ddl.e-input-group .e-input-group-icon,
.e-ddl.e-input-group.e-control-wrapper {
    background-color: white;
} */

#ProjectSite_id, #TripRates_id {
    all: unset;
    cursor: default;
}
